.sectionAbout {
    position: relative;
    @media (max-width: 767px) {
        height: 700px;
    }

    .bgAboutContent {
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

        @media (min-width: 1440px) {
            max-height: 1000px;
        }

        .imgBgAbout {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: -1;

            @media (min-width: 1440px) {
                object-fit: contain;
            }
        }
    }

    .sectionText {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        position: absolute;
        bottom: 0;

        @media (min-width: 1024px) {
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media (min-width: 1440px) {
            display: none;
        }

        .videoContent {
            padding-top: 1rem;

            @media (min-width: 768px) {
                max-width: 420px;
                max-height: 350px;
                margin: 0 auto;
            }

            @media (min-width: 1024px) {
                height: auto;
                width: 100%;
                max-width: 620px;
                max-height: 450px;
            }

            @media (min-width: 1440px) {
                max-width: 720px;
                max-height: 550px;
            }
        }

        .textContent {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0.7rem;

            .textAbout {
                color: var(--secondary-color);
                font-size: 12px;
                font-weight: 400;

                @media (min-width: 1024px) {
                    font-size: 14px;
                }
            }

            .textTop {
                margin-top: 1rem;
                color: white;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 1rem;

                @media (min-width: 768px) {
                    line-height: normal;
                }

                @media (min-width: 1024px) {
                    font-size: 26px;
                }
            }

            .textNormal {
                color: white;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 1rem;

                @media (min-width: 768px) {
                    font-size: 12px;
                }

                @media (min-width: 1024px) {
                    font-size: 14px;
                }
            }
        }
    }

    .sectionTextLg {
        display: none;
        padding-top: 10rem;
        padding-bottom: 10rem;

        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        position: absolute;
        bottom: 0;

        @media (min-width: 1440px) {
            display: block;
            z-index: 1;
            padding-left: 12rem;
            padding-right: 12rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 0 0 2rem;
        }

        .contentText {
            display: grid;
            grid-template-columns: 50% 50%;

            .videoWrap {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .textWrap {
                padding-left: 2rem;
                padding-right: 2rem;

                .textAbout {
                    color: var(--secondary-color);
                    font-size: 12px;
                    font-weight: 400;

                    @media (min-width: 1024px) {
                        font-size: 14px;
                    }
                }

                .textTop {
                    margin-top: 1rem;
                    color: white;
                    font-size: 48px;
                    font-weight: 500;
                    margin-bottom: 3rem;
                    line-height: 50px;
                }

                .textNormal {
                    color: white;
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
