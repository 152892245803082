.sectionExperience {
    // background-color: black;
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../../assets/images/home/experience/bg-experience-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../../assets/images/home/experience/ex-home-lg.png');
    }

    @media (min-width: 1440px) {
        height: 840px;
    }

    .titleSection {
        text-align: center;
        padding-top: 2rem;
        color: var(--secondary-color);
        font-size: 14px;
        font-weight: 400;



        @media (min-width: 1440px) {
            display: none;
        }
    }

    .bottomTitle {
        color: var(--font-color);
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 2rem;

        @media (min-width: 1440px) {
            font-size: 48px;
            display: none;
        }
    }

    .sectionBg {
        position: relative;

        .imgBg {
            width: 100%;

            @media (min-width: 768px) {
                display: none;
            }
        }

        .imgBgLg {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }

            @media (min-width: 1440px) {
                width: 100%;
            }
        }

        .lgContent {
            @media (min-width: 768px) {
                margin-top: 2rem;
                padding-bottom: 2rem;
            }

            @media (min-width: 1440px) {
                margin-top: 15rem;
            }

            .text {

                .titleSectionLg {
                    text-align: center;
                    padding-top: 2rem;
                    color: var(--secondary-color);
                    font-size: 14px;
                    font-weight: 400;

                    h4 {
                        font-size: 12px;
                    }
                }

                .bottomTitleLg {
                    h5 {
                        color: var(--font-color);
                        font-size: 24px;
                        font-weight: 500;
                        text-align: center;
                        margin-bottom: 2rem;

                        @media (min-width: 1440px) {
                            font-size: 48px;
                        }
                    }
                }
            }

            .sectionContentEx {
                padding-left: 1rem;
                padding-right: 1rem;
                z-index: 1;

                @media (min-width: 768px) {
                    display: grid;
                    grid-template-columns: 33% 33% 33%;
                    gap: 5px;
                }

                @media (min-width: 1024px) {
                    top: 10rem;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-left: 4rem;
                    padding-right: 4rem;
                    grid-column-gap: 24px;
                }

                @media (min-width: 1440px) {
                    position: initial;
                    padding-top: 1rem;
                    top: 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-left: 12rem;
                    padding-right: 12rem;
                    grid-column-gap: 24px;
                    height: 19rem;
                }

                .listItem {
                    border: 1px solid var(--secondary-color);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1.5rem;
                    width: 100%;
                    padding: 2px;

                    @media (min-width: 375px) {
                        padding: 10px;
                    }

                    @media (min-width: 425px) {
                        padding: 25px;
                    }

                    @media (min-width: 768px) {
                        padding: 0px;
                    }

                    @media (min-width: 1024px) {
                        padding: 10px;
                        width: 30%;
                        flex-grow: 1;
                    }

                    .itemImg {
                        width: 20%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid var(--secondary-color);
                        height: 100%;
                        padding-top: 15px;
                        padding-bottom: 15px;

                        @media (min-width: 768px) {
                            height: 65%;
                        }
                    }

                    .listText {
                        margin-left: 20px;
                        width: 80%;

                        @media (min-width: 768px) {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }

                        .itemText {
                            color: var(--secondary-color);
                            font-size: 18px;
                            font-weight: 500;
                            margin-bottom: 0;

                            @media (min-width: 768px) {
                                font-size: 14px;
                            }
                        }

                        .itemDesc {
                            color: var(--font-color);
                            font-size: 14px;
                            font-weight: 400;
                            padding-right: 1rem;

                            p {
                                margin-bottom: 0;
                            }

                            @media (min-width: 768px) {
                                font-size: 10px;
                            }

                            @media (min-width: 1024px) {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .secSeeMore {
                    padding: 1rem 1rem 2rem 1rem;
                    text-align: center;

                    @media (min-width: 768px) {
                        display: none;
                    }

                    .subSeeMore {
                        border-bottom: 1px solid var(--secondary-color);
                        width: 120px;
                        margin: 0 auto;

                        .viewMore {
                            cursor: pointer;
                            display: inline-block;
                            color: var(--secondary-color);
                            margin-right: 5px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}