.btnBooking {
    margin-top: 1rem;
    padding: 0.1em 0.7em;
    width: 100%;
    display: block;
    background: linear-gradient(45deg, #c5a052, #f3e695, #b9974b);
    border-radius: 8px;
    border-color: transparent;
    color: var(--black-color);
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;

    @media (min-width: 768px) {
        padding: 0.1em 0.7em;
    }

    @media (min-width: 1024px) {
        font-size: 14px;
        padding: 0.2em 0.7em;
    }

    @media (min-width: 1440px) {
        font-size: 16px;
        padding: 0.5em 0.7em;
    }

    @media (min-width: 2560px) {
        font-size: 1em;
        padding: 0.7em 0.7em;
    }
}
