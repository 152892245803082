$required-color: #ff1b1b;

$input-border-color: var(--black-color);
$input-font-color: #727272;
$input-font-size: 14px;

$label-font-size: 18px;
$label-font-weight: 400;

$placeholder-color: #a9a7a6;
$placeholder-font-size: 14px;
$placeholder-font-weight: 400;

.requiredTextColor {
    color: $required-color;
}

.label {
    display: block;
    font-weight: $label-font-weight;
    font-size: $label-font-size;
}

.input {
    width: 100%;
    padding: 0.9em 0.9em 0.9em 0;
    font-size: $input-font-size;
    border: 0;
    outline: 0;
    border-bottom: 1px solid $input-border-color;
    border-color: $input-border-color;

    &:focus {
        border-color: $input-border-color;
    }
}

.input::placeholder {
    color: $placeholder-color;
    font-size: $placeholder-font-size;
    font-weight: $placeholder-font-weight;
}

.inputModalMap {
    position: absolute;
    top: -3em;
    width: 90%;
    padding: 0.9em 0.9em 0.9em 0.9em;

    border: 2px solid #e0d4ca;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    padding-left: 5px;

    &:focus {
        border-color: $input-border-color;
    }
}

.inputMapSelectStyleValue {
    color: var(--black-color);
}

.inputMapSelectStyle {
    color: $placeholder-color;
    font-size: $placeholder-font-size;
    font-weight: $placeholder-font-weight;
    margin: 0;
}

.inputModalMap::placeholder {
    color: $placeholder-color;
    font-size: $placeholder-font-size;
    font-weight: $placeholder-font-weight;
}

.suggestionWrapperModalMap {
    margin: 0;
    width: 96%;
    overflow-x: hidden;
    list-style: none;
    margin: 0 auto;
    display: block;
    padding: 4px;
    position: absolute;
    background-color: var(--white-color);
    z-index: 1;
}

.suggestionWrapperModalMap>li {
    padding: 8px 4px;
    background-color: var(--white-color);
    margin: 4px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.textRequired {
    color: $required-color;
    font-size: 12px;
}

.suggestionWrapper {
    margin: 0;
    width: 96%;
    overflow-x: hidden;
    list-style: none;
    margin: 0 auto;
    display: block;
    padding: 4px;
}

.suggestionWrapper>li {
    padding: 8px 4px;
    background-color: var(--white-color);
    margin: 4px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.modalMapWrapper {
    // position: fixed;
    width: 100%;
    height: 600px;
    // top: 10%;
    // left: 0;
    // right: 0;
    // bottom: 0;
    max-width: 600px;
    // margin: 0 auto;
    // display: block;
    background-color: rgb(0 0 0 / 89%);
    transition: 0.2s ease all;
    z-index: 2;

    .modalMap {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        background-color: #ededed;
        border-radius: 5px;

        .mapContainer {
            height: 80%;
            flex-flow: row nowrap;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 20px;
            position: relative;

            .btnCloseMap {
                position: absolute;
                bottom: -2em;
                width: 80%;
                padding: 0.44em 0.5em;
                display: block;
                font-size: 18px;
                font-weight: 700;
                background: linear-gradient(45deg, #c5a052, #f3e695, #b9974b);
                border-radius: 8px;
                border-color: transparent;
                color: var(--black-color);
            }
        }
    }
}

.btnDropOff {
    margin-top: 1em;
    cursor: pointer;
    border: 2px solid #daa520;
    border-radius: 8px;
    background-color: transparent;
    padding: 0.6em 2.4em;
    width: 100%;

    @media (min-width: 768px) {
        width: unset;
    }

    span {
        font-size: 14px;
        font-weight: 700;
        color: #daa520;
    }
}