.contentServicesPrice {
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;

    .headingContent {
        text-align: center;
        padding-top: 3rem;

        @media (min-width: 1024px) {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .cardCarContent {
        margin-top: 8rem;

        .alphardContent {
            @media (min-width: 768px) {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .card {
                margin-bottom: 5rem;
                max-width: 450px;
                cursor: pointer;

                .alphardPromo {
                    margin-bottom: 2rem;
                    position: relative;

                    .bgPromo {
                        object-fit: cover;
                        width: 100%;
                        height: auto;
                        position: absolute;
                        bottom: 3rem;
                        z-index: 1;
                    }

                    .promoContent {
                        z-index: 1;
                        display: grid;
                        grid-template-columns: 50% 50%;
                        width: 100%;
                        position: absolute;
                        bottom: 3rem;

                        .text {
                            color: white;
                            font-size: 20px;
                            text-align: center;

                            @media (min-width: 1440px) {
                                font-size: 30px;
                            }

                            p {
                                margin: 12px 0 0 0;
                            }
                        }

                        .price {
                            color: white;
                            font-size: 25px;

                            @media (min-width: 1440px) {
                                font-size: 35px;
                            }

                            p {
                                margin: 0 0 0 5px;
                            }
                        }
                    }
                }

                &:hover {
                    transform: translateY(-10px);
                    transition: 0.3s;
                }

                @media (min-width: 768px) {
                    max-width: 400px;
                    width: 100%;
                }

                @media (min-width: 1024px) {
                    max-width: 400px;
                    width: 100%;
                }

                @media (min-width: 1440px) {
                    max-width: 550px;
                    width: 100%;
                }

                .cardCar {
                    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%),
                        0 6px 20px 0 rgb(0 0 0 / 5%);
                    border-top-right-radius: 25px;
                    border-top-left-radius: 25px;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    justify-content: end;

                    @media (min-width: 1440px) {
                        height: 250px;
                    }

                    .imgCarContent {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        width: 100%;

                        @media (min-width: 768px) {
                            padding: 0 20px 0 20px;
                        }

                        .imgCar {
                            width: 100%;
                            height: auto;
                            object-fit: contain;
                            max-height: 150px;

                            @media (min-width: 1440px) {
                                max-height: 250px;
                            }
                        }
                    }

                    .detailCar {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding: 10px;

                        .carName {
                            margin-bottom: 0;
                        }

                        .carPrice {
                            margin-bottom: 0;
                            color: #b8b8b8;
                            text-decoration: line-through;
                            text-decoration-color: red;
                            text-decoration-thickness: 1px;
                        }
                    }
                }
            }
        }

        .otherCarContent {
            padding-bottom: 3rem;

            @media (min-width: 768px) {
                display: grid;
                grid-template-columns: 50% 50%;
                max-width: 768px;
                margin: 0 auto;
            }

            @media (min-width: 1024px) {
                display: grid;
                grid-template-columns: 33% 34% 33%;
                max-width: 1024px;
                margin: 0 auto;
            }

            @media (min-width: 1024px) and (orientation: landscape) {
                display: grid;
                grid-template-columns: 33% 34% 33%;
                max-width: 55rem;
                margin: 0 auto;
            }

            @media (min-width: 1440px) {
                display: grid;
                grid-template-columns: 33% 34% 33%;
                max-width: 1440px;
                margin: 0 auto;
            }

            .card {
                padding-bottom: 5rem;
                max-width: 450px;
                cursor: pointer;

                &:hover {
                    transform: translateY(-10px);
                    transition: 0.3s;
                }

                @media (min-width: 768px) {
                    max-width: 350px;
                    margin: 0 auto;
                    width: 100%;
                }

                @media (min-width: 1024px) {
                    max-width: 315px;
                    width: 100%;
                }

                @media (min-width: 1440px) {
                    max-width: 470px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .cardCar {
                    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%),
                        0 6px 20px 0 rgb(0 0 0 / 5%);
                    border-top-right-radius: 25px;
                    border-top-left-radius: 25px;
                    height: 170px;
                    display: flex;
                    flex-direction: column;
                    justify-content: end;

                    .imgCarContent {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        width: 100%;

                        @media (min-width: 768px) {
                            padding: 0 20px 0 20px;
                        }

                        .imgCar {
                            width: 100%;
                            height: auto;
                            object-fit: contain;
                            max-height: 150px;

                            @media (min-width: 1024px) {
                                object-fit: cover;
                            }

                            @media (min-width: 1440px) {
                                object-fit: contain;
                            }
                        }
                    }

                    .detailCar {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding: 10px;

                        .carName {
                            margin-bottom: 0;
                        }

                        .carPrice {
                            margin-bottom: 0;
                            color: #daa520;
                        }
                    }
                }
            }
        }
    }
}

.alphardPromo {
    // margin-bottom: 2rem;
    position: relative;
    max-width: 300px;
    margin: 0 auto;
    .bgPromo {
        object-fit: cover;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 2rem;
        z-index: 1;
    }

    .promoContent {
        z-index: 1;
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        position: absolute;
        bottom: 3rem;

        .text {
            color: white;
            font-size: 20px;
            text-align: center;

            @media (min-width: 1440px) {
                font-size: 30px;
            }

            p {
                margin: 12px 0 0 0;
            }
        }

        .price {
            color: white;
            font-size: 25px;

            @media (min-width: 1440px) {
                font-size: 35px;
            }

            p {
                margin: 0 0 0 5px;
            }
        }
    }
}
