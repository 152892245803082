.sectionGallery {
    background-color: black;
    border: 0.5px solid black;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;

    .titleGallery {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: var(--font-color);
        margin-bottom: 2rem;

        p {
            margin-bottom: 0;
        }

        @media (min-width: 1440px) {
            font-size: 48px;
        }
    }

    .imageList {
        @media (min-width: 768px) {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media (min-width: 1024px) {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        li {
            .backDrop {
                display: none;
            }
        }

        li {
            &:last-child {
                position: relative;

                .backDrop {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                }
            }
        }

    }

    .imageListLg {
        display: none;

        @media (min-width: 1024px) {
            display: grid;
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media (min-width: 1440px) {
            padding-left: 12rem;
            padding-right: 12rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media (min-width: 1024px) {
                object-fit: cover;
            }
        }
    }

    .containerBottom {
        display: none;

        @media (min-width: 1024px) {
            display: block;
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media (min-width: 1440px) {
            padding-left: 12rem;
            padding-right: 12rem;
        }

        .shadowContent {
            width: 100%;
            height: 100px;
            background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(34, 34, 34, 70%));
        }
    }
}

.image-modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 0 15px;
    outline: none;

    &.image-modal-wrapper-lg {
        width: 700px;
    }

    .image-modal {
        border: 2px solid #daa520;
        background-color: black;
        object-fit: contain;
    }
}