.topHomeBg {
    background: #d0cfcf;

    @media (min-width: 1024px) {
        background: #000000cc;
    }

    .TopHomeContent {
        position: relative;

        .bgContent {
            width: 100%;
            height: 100%;
            z-index: 1;
            border-top-left-radius: 25px;

            .imgBg {
                background-color: black;
                width: 100%;
                height: 500px;
                object-fit: cover;
                border-top-left-radius: 25px;

                @media (min-width: 768px) {
                    visibility: hidden;
                }
            }

            .imgBgLg {
                visibility: hidden;
                background-color: black;
                width: 100%;
                height: 100%;
                border-top-left-radius: 25px;

                @media (min-width: 768px) {
                    visibility: visible;
                }
            }
        }

        .TopHomeText {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            z-index: 2;
            position: absolute;
            bottom: 0;
            width: 100%;

            @media (min-width: 768px) {
                padding: 0;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
            }

            @media (min-width: 1024px) {
                height: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 1rem;
                padding-left: 2rem;
                padding-right: 2rem;
                padding-bottom: 0.4rem;
                bottom: 75px;
            }

            @media (min-width: 1440px) {
                padding-bottom: 0.8rem;
                padding-top: 2rem;
            }

            @media (min-width: 2560px) {
                padding-bottom: 4rem;
            }

            .sectionTextBottom {
                height: 90%;
                display: none;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 1rem;

                @media (min-width: 768px) {
                    height: 65%;
                    padding-top: 0;
                }

                @media (min-width: 1024px) {
                    justify-content: flex-start;
                }

                .textTop {
                    display: none;
                    color: var(--secondary-color);
                    font-size: 20px;
                    font-weight: 200;

                    @media (min-width: 1024px) {
                        display: block;
                        font-size: 15px;
                        margin-top: 0;
                    }

                    @media (min-width: 1440px) {
                        display: block;
                        font-size: 20px;
                        margin-top: 0;
                    }
                }

                .textBottom {
                    display: none;
                    color: white;
                    font-size: 25px;
                    font-weight: 400;

                    @media (min-width: 1024px) {
                        display: block;
                        font-size: 30px;
                        color: white;
                        margin-bottom: 0;
                    }

                    @media (min-width: 1440px) {
                        display: block;
                        font-size: 35px;
                        color: white;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .btnBooking {
            display: block;
            padding-left: 4rem;
            padding-right: 4rem;
            padding-bottom: 1rem;

            @media (min-width: 768px) {
                padding-left: 12rem;
                padding-right: 12rem;
                padding-bottom: 0;
            }

            @media (min-width: 1024px) {
                display: none;
            }
        }

        .btnBookingLg {
            position: relative;

            @media (min-width: 1024px) {
                padding-left: 1rem;
                padding-right: 1rem;
                max-height: 44px;
                max-width: 850px;
                margin: 0 auto;
                width: 100%;
            }

            @media (min-width: 1440px) {
                padding-left: 8rem;
                padding-right: 8rem;
                padding-bottom: 0.7rem;
                max-height: 60px;
                max-width: unset;
            }

            @media (min-width: 2560px) {
                padding-left: 20rem;
                padding-right: 20rem;
                max-width: unset;
            }

            .contentBottom {
                display: none;
                height: 10%;

                @media (min-width: 1024px) {
                    display: flex;
                    flex-direction: row;
                    background: linear-gradient(#ececec 92%, #e5e5e5 53%);
                    height: 100%;
                    padding: 0.4rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                }

                @media (min-width: 1440px) {
                    display: grid;
                    grid-template-columns: 20% 20% 20% 20% 10%;
                    box-shadow: inset 0 0 50px #676666;
                    padding-bottom: 0.4rem;
                    width: 100%;
                    max-width: unset;
                    height: 70px;
                }

                @media (min-width: 2560px) {
                    height: 120px;
                }

                .bookingLg {
                    display: flex;
                    align-items: center;
                }

                .selectBookingMenu {
                    border-right: 1px solid #e0d4ca;
                    padding-right: 10px;
                    padding-left: 10px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    line-height: 18px;

                    @media (min-width: 1024px) {
                        line-height: 18px;
                        margin-right: 16px;
                    }

                    .bookingMenu {
                        width: 100%;
                        margin-right: 10px;

                        @media (min-width: 1440px) {
                            margin-right: 20px;
                        }

                        .bookingTitle {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                width: 0.8em;
                                height: 0.8em;
                            }

                            .title {
                                font-size: 12px;
                                font-weight: 700;
                                margin-left: 5px;
                                overflow: hidden;
                                max-height: 55px;
                                margin-bottom: 0;
                                font-weight: normal;

                                @media (min-width: 1024px) {
                                    font-size: 14px;
                                }

                                @media (min-width: 1440px) {
                                    font-size: 17px;
                                }
                            }
                        }

                        .bookingDesc {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .desc {
                                font-size: 10px;
                                color: #635855;
                                margin-bottom: 0;

                                @media (min-width: 1024px) {
                                    font-size: 15px;
                                }

                                @media (min-width: 1440px) {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }

            .optionDetail {
                // display: none;
                position: absolute;
                z-index: 2;
                bottom: -319px;
                width: 630px;
                height: auto;
                background-color: white;
                border-radius: 5px;

                .wrapOption {
                    .listCar {
                        .btn {
                            width: 100%;
                            text-align: start;
                            border: none;
                            font-size: 16px;
                            font-weight: 200;
                            background-color: white;
                            padding: 10px;
                            border-radius: 5px;

                            &:hover {
                                background-color: #daa520;
                            }
                        }
                    }
                }
            }

            .pickLocation {
                position: absolute;
                z-index: 2;
                width: 630px;
                height: auto;
                border-radius: 5px;

                .wrapOption {
                    display: flex;
                    flex-direction: column;

                    .formLocation {
                        display: grid;
                        grid-template-columns: 80% 20%;

                        .inputLocation {
                            margin-right: 5px;
                            font-size: 16px;
                            font-weight: 300;
                            border: 2px solid #e0d4ca;
                            border-radius: 5px;
                            font-size: 16px;
                            font-weight: 300;
                        }

                        .btnSubmit {
                            padding: 0.25em 0.7em;
                            width: 100%;
                            display: block;
                            background: linear-gradient(
                                45deg,
                                #c5a052,
                                #f3e695,
                                #b9974b
                            );
                            border-radius: 8px;
                            border-color: transparent;
                            color: var(--black-color);
                            text-decoration: none;
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }

                    .contactSectionMap {
                        .googleMapContent {
                            width: 100%;
                            height: 295px;
                            border-radius: 10px;
                        }
                    }
                }
            }

            .pickUpDate {
                position: absolute;
                z-index: 2;
                width: 630px;
                height: auto;
                border-radius: 5px;
                background-color: white;
                padding: 5px 10px 5px 10px;

                @media (min-width: 1440px) {
                    right: 400px;
                }

                @media (min-width: 2560px) {
                    right: 750px;
                }

                .contentSelect {
                    display: grid;
                    grid-template-columns: 50% 50%;

                    .date {
                        padding-left: 10px;
                        padding-right: 5px;

                        label {
                            font-size: 20px;
                            font-weight: 300;
                        }

                        .pickDate {
                            width: 100%;

                            .datePicker {
                                width: 100%;

                                input {
                                    border: 2px solid #e0d4ca;
                                    border-radius: 5px;
                                    font-size: 16px;
                                    font-weight: 300;
                                    width: 100%;
                                    cursor: pointer;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }

                    .time {
                        padding-left: 5px;
                        padding-right: 10px;

                        label {
                            font-size: 20px;
                            font-weight: 300;
                        }

                        .pickTime {
                            width: 100%;

                            .timePicker {
                                width: 100%;

                                input {
                                    border: 2px solid #e0d4ca;
                                    border-radius: 5px;
                                    font-size: 16px;
                                    font-weight: 300;
                                    width: 100%;
                                    cursor: pointer;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }
            }

            .dropLocation {
                position: absolute;
                z-index: 2;
                width: 630px;
                height: auto;
                border-radius: 5px;

                @media (min-width: 1440px) {
                    right: 400px;
                }

                @media (min-width: 2560px) {
                    right: 750px;
                }

                .pickLocation {
                    .wrapOption {
                        display: flex;
                        flex-direction: column;

                        .formLocation {
                            display: grid;
                            grid-template-columns: 80% 20%;

                            .inputLocation {
                                margin-right: 5px;
                                font-size: 16px;
                                font-weight: 300;
                                border: 2px solid #e0d4ca;
                                border-radius: 5px;
                                font-size: 16px;
                                font-weight: 300;
                            }

                            .btnSubmit {
                                padding: 0.25em 0.7em;
                                width: 100%;
                                display: block;
                                background: linear-gradient(
                                    45deg,
                                    #c5a052,
                                    #f3e695,
                                    #b9974b
                                );
                                border-radius: 8px;
                                border-color: transparent;
                                color: var(--black-color);
                                text-decoration: none;
                                font-size: 18px;
                                font-weight: 700;
                            }
                        }

                        .contactSectionMap {
                            .googleMapContent {
                                width: 100%;
                                height: 295px;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
